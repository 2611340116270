<template>
    <section class="main-content-section">
        <div class="grid">
            <div class="col-8-12 push-2-12 sm-col-1-1 sm-push-0">
                <div v-if="order.lines.length > 0" class="register-product-summary">
                    <h2 class="page-title">{{selectedProducts}}</h2>
                    <div v-for="line in order.lines" :key="line.id" class="flex justify-space-between register-product">                     
                        <figure>
                            <img :src="line.imageUrl" :alt="line[`name${selectedLanguage}`]">
                        </figure>
                        <div class="flex flex-column justify-end">
                            <h4>{{line[`name${selectedLanguage}`]}}</h4>
                            <div class="flex justify-end">
                                <span>{{amount}}</span>
                                <span>{{line.quantity}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="isLoading" class="col-1-1">
                <LoadingSpinner />
            </div>

            <div v-else class="col-8-12 push-2-12 sm-col-1-1 sm-push-0">
                <div class="form-container register-form">
                    <div class="form-header">
                       <h1 class="page-title">{{createYourPassword}}</h1>
                        <div class="info">{{needToCreateAPassword}}</div>
                    </div>

                    <form @submit.prevent="submitForm()">
                        <div class="form-row flex flex-column">
                            <label for="password">{{passwordLabel}} *</label>
                            <input 
                                id="password"
                                type="password"
                                autocomplete="off" 
                                v-model.trim="password"
                                @input="$v.password.$touch()"
                                :class ="{ error: $v.password.$error }"
                                @blur="saveDataToLocalStorage('password', password)"
                            />
                            <div v-if="$v.password.$dirty">
                                <p class="error" v-if="!$v.password.required">{{passwordLabel}} {{isRequired}}</p>
                                <p class="error" v-if="!$v.password.minLength">{{passwordMinLength}}</p>
                                <p class="error" v-if="!$v.password.valid">{{passwordMustBeStronger}}</p>
                            </div>
                        </div>

                        <div class="form-row flex flex-column">
                            <label for="passwordConfirmation">{{confirmPasswordLabel}} *</label>
                            <input 
                                id="passwordConfirmation"
                                type="password"
                                autocomplete="off" 
                                v-model.trim="passwordConfirmation"
                                @input="$v.passwordConfirmation.$touch()"
                                :class ="{ error: $v.passwordConfirmation.$error }"
                                @blur="saveDataToLocalStorage('passwordConfirmation', passwordConfirmation)"
                            />
                            <div v-if="$v.passwordConfirmation.$dirty">
                                <p class="error" v-if="!$v.passwordConfirmation.required">{{confirmPasswordLabel}} {{isRequired}}</p>
                                <p class="error" v-if="!$v.passwordConfirmation.sameAsPassword">{{incorrectPasswordConfirmation}}</p>
                            </div>
                        </div>

                        <div class="form-row">
                            <input type="checkbox" id="terms" v-model="terms" @change="handleCheckboxEvent('terms', terms)" :class="{ error: $v.terms.$error }">
                            <label for="terms"><div class="custom-checkbox"></div>{{accept}} <router-link to="/terms">{{termsAndConditions.toLowerCase()}}</router-link> *</label>
                            <div v-if="$v.terms.$invalid && $v.terms.$dirty">
                                <p class="error" v-if="$v.terms.required">{{termsAndConditions}} {{isRequired}}</p>
                            </div>
                        </div>

                        <div class="form-row">
                            <input type="checkbox" id="privacyPolicy" v-model="privacyPolicy" @change="handleCheckboxEvent('privacyPolicy', privacyPolicy)" :class="{ error: $v.privacyPolicy.$error }">
                            <label for="privacyPolicy"><div class="custom-checkbox"></div>{{accept}} <router-link to="/privacy">{{privacyPolicyLabel.toLowerCase()}}</router-link> *</label>
                             <div v-if="$v.privacyPolicy.$invalid && $v.privacyPolicy.$dirty">
                                <p class="error" v-if="$v.privacyPolicy.required">{{privacyPolicyLabel}} {{isRequired}}</p>
                            </div>
                        </div> 

                        <div class="form-row">
                            <span class="small">* {{indicatesRequiredField}}</span>
                        </div>

                        <div class="form-row flex justify-space-between">
                            <button @click="navigateToPreviousStep()" class="btn btn-secondary">{{previousStep}}</button>
                            <button type="submit" class="btn btn-primary alternative submitBtn">{{nextStep}} (3/3)</button>
                        </div>                      
                    </form>
                </div>
            </div>
        </div>
        <WarningMessageModal v-show="showWarningModal" :title="errorTitle" :text="errorMessage" @closeModal="closeModal()"/>
    </section>
</template>

<script>
import LoadingSpinner from '../../components/ui/LoadingSpinner.vue';
import { mapGetters } from 'vuex';
import { required, sameAs, minLength } from 'vuelidate/lib/validators';
import agent from '../../api/agent';
import WarningMessageModal from '@/components/modals/WarningMessageModal';

export default {
    name: 'RegisterStepThree',
    components: {WarningMessageModal, LoadingSpinner},

    data() {
        return {
            isLoading: false,
            password: '',
            passwordConfirmation: '',
            terms: false,
            privacyPolicy: false,
            errorTitle: '',
            errorMessage: '',
            showWarningModal: false,
        }
    },

    validations: {
        password: {
            required,
            minLength: minLength(8),
            valid: (value) => {
                const containsUppercase = /[A-Z]/.test(value);
                const containsLowercase = /[a-z]/.test(value);
                const containsNumber = /[0-9]/.test(value);
                const containsSpecial = /[#?!@$%^&*-]/.test(value);
                return (
                    containsUppercase &&
                    containsLowercase &&
                    containsNumber &&
                    containsSpecial
                ); 
            }
        },
        passwordConfirmation: { required, sameAsPassword: sameAs("password") },
        terms: { required, sameAsChecked: sameAs(() => true) },
        privacyPolicy: { required, sameAsChecked: sameAs(() => true) },
    },

    created() {
        this.setDocumentTitle();

        if (!this.order && this.$route.query.orderId) {
            this.$store.dispatch('fetchOrder', this.$route.query.orderId);
        }

        this.password = this.$store.getters.getPassword || localStorage.getItem('password');
        this.passwordConfirmation = this.$store.getters.getPasswordConfirmation || localStorage.getItem('passwordConfirmation');
        this.terms = this.$store.getters.getTerms || !!localStorage.getItem('terms');
        this.privacyPolicy = this.$store.getters.getPrivacyPolicy || !!localStorage.getItem('privacyPolicy');
    },

    updated() {
        this.setDocumentTitle();
    },

    computed: {
        ...mapGetters([
            'metaTitle',
            'selectedLanguage',
            'recaptchaValidation',
            // Texts
            'isRequired',
            'passwordMinLength',
            'createYourPassword',
            'needToCreateAPassword',
            'incorrectPasswordConfirmation',
            'passwordMustBeStronger',
            'accept',
            'termsAndConditions',
            'indicatesRequiredField',
            'notAllRequiredFieldsAreCompleted',
            'previousStep',
            'nextStep',
            'amount',
            'selectedProducts'
        ]),
        ...mapGetters({
            order: 'getOrder',
            orderId: 'getOrderId',
            // Texts
            passwordLabel: 'password',
            confirmPasswordLabel: 'confirmPassword',
            privacyPolicyLabel: 'privacyPolicy',
        })
    },

    methods: {
        setDocumentTitle() {
            document.title = this.createYourPassword + this.metaTitle;
        },

        showModal() {
            this.showWarningModal = true;
        },

        closeModal() {
            this.showWarningModal = false;
        },

        saveDataToStore() {
            this.$store.dispatch('setPassword', this.password);
            this.$store.dispatch('setPasswordConfirmation', this.passwordConfirmation);
            this.$store.dispatch('setTerms', this.terms);
            this.$store.dispatch('setPrivacyPolicy', this.privacyPolicy);
        },

        saveDataToLocalStorage(key, data) {
            localStorage.setItem(key, data);
        },

        handleCheckboxEvent(key, data) {
            this.$v[key].$touch();
            this.saveDataToLocalStorage(key, data);
        },

        navigateToPreviousStep() {
            this.saveDataToStore();
            this.$router.push(`/register/step-2?orderId=${this.$route.query.orderId}`);
        },

        removeAccountData() {
            localStorage.removeItem('firstName');
            localStorage.removeItem('lastName');
            localStorage.removeItem('email');
            localStorage.removeItem('emailConfirmation');
            localStorage.removeItem('phone');
            localStorage.removeItem('mobilePhone');
            localStorage.removeItem('cardNumber');
            localStorage.removeItem('collector');
            localStorage.removeItem('password');
            localStorage.removeItem('passwordConfirmation');
            localStorage.removeItem('terms');
            localStorage.removeItem('privacyPolicy');
        },

        async registerUser() {
            const registerData = {
                firstName: this.$store.getters.getFirstname || localStorage.getItem('firstName'),
                lastName: this.$store.getters.getLastname || localStorage.getItem('lastName'),
                email: this.$store.getters.getEmail || localStorage.getItem('email'),
                password: this.password || localStorage.getItem('password'),
                phone: this.$store.getters.getPhone || localStorage.getItem('phone'),
                mobile: this.$store.getters.getMobile || localStorage.getItem('mobilePhone'),
                isDigitalSaver: this.$store.getters.getDigitalCollector || !!localStorage.getItem('collector'),
                acceptedTerms: this.terms && this.privacyPolicy || !!localStorage.getItem('terms') && !!localStorage.getItem('privacyPolicy'),
            }
                
            // this.isLoading = true;
            
            try {
                const userId = await agent.service.registerUser(registerData);
                // const userId = await agent.users.register(registerData);
                
                this.$store.dispatch('setUserId', this.userId);
                this.$store.dispatch('setAuthenticated', true);

                this.removeAccountData();
                    
                try {
                    // const confirmedOrder = await agent.order.confirm({
                    //     orderId: this.$route.query.orderId,
                    //     userId: userId,
                    //     languageNumber: this.$store.getters.selectedLanguage
                    // });
                    const confirmedOrder = await agent.service.confirmOrder({
                        orderId: this.$route.query.orderId,
                        userId: userId,
                        languageNumber: this.$store.getters.selectedLanguage,
                        sendEmailConfirmation: true
                    });

                    this.isLoading = false;
                    this.$store.commit('CLEAR_ORDER');
                    this.$store.commit('REMOVE_USER');
                    this.$router.push(`/register/thank-you?orderId=${confirmedOrder}`);
                } catch (error) {
                    console.log(error.response);
                    this.isLoading = false;
                    this.errorTitle = error.response.data.title;
                    this.errorMessage = error.response.data.detail;
                    this.showModal();
                }
            } catch (error) {
                console.log(error.response);
                this.isLoading = false;
                this.errorTitle = error.response.data.title;
                this.errorMessage = error.response.data.detail;
                this.showModal();
            }
        },

        async validateRecaptcha(token) {
            await this.$store.dispatch('validateRecaptcha', token);
            if (this.recaptchaValidation === true) {
                this.registerUser();
            } else {
                this.isLoading = false;
                this.errorTitle = 'Something went wrong';
                this.errorMessage = 'Please try again';
                this.showModal();
            }
        },

        async getRecaptchaToken() {
            this.isLoading = true;
            await this.$recaptchaLoaded();
            const token = await this.$recaptcha('login');
            this.validateRecaptcha(token);
        },

        async submitForm() {
            if (this.$v.password.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.passwordLabel + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            if (this.$v.passwordConfirmation.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.confirmPasswordLabel + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            if (this.$v.terms.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.termsAndConditions + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            if (this.$v.privacyPolicy.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.privacyPolicyLabel + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            this.saveDataToStore();

            if(!this.$v.$invalid) {
                this.getRecaptchaToken();
            }
        }
    }
}
</script>